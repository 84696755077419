import React from "react";
import { useState, useEffect } from "react";
import About from "../components/about";
// import BlogHome from "../components/blogHome";
import Contact from "../components/contact";
import Footer from "../components/footer";
import NavbarMobile from "../components/header/navbarMobile";
import GetInTouch from "../components/header/getInTouch";
import NavbarDesktop from "../components/header/navbarDesktop";

/* To add BlogHome again:
    1. uncomment all references to BlogHome in this component
    2. pass blogHome state to NavbarMobile blogHome={blogHome}
    3. uncomment blog link and references in navbarMobile, NavbarDesktop, and Footer
*/

const Home = () => {
  const [getInTouchHeight, setGetInTouchHeight] = useState(0);
  const [contact, setContact] = useState(null);
  const [about, setAbout] = useState(null);
  const [services, setServices] = useState(null);
  // const [blogHome, setBlogHome] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
  });

  return (
    <div>
      <NavbarMobile contact={contact} about={about} services={services} />
      <GetInTouch setHeight={setGetInTouchHeight} screenWidth={screenWidth} />
      <NavbarDesktop
        headerHeight={getInTouchHeight}
        screenWidth={screenWidth}
      />
      <About setAbout={setAbout} setServices={setServices} />
      {/* <BlogHome setBlogHome={setBlogHome} /> */}
      <Contact setContact={setContact} />
      <Footer />
    </div>
  );
};

export default Home;
