import { useEffect, useState } from "react";

const UseForm = (validate, postRequest) => {
  const [inputs, setInputs] = useState({
    email: "",
    message: "",
    nickname: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [carajilloResponse, setCarajilloResponse] = useState();
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (Object.keys(errors) == 0 && isSubmitting) {
      setFetching(true);
      postRequest(inputs, setCarajilloResponse, setIsSubmitted);
    }
    setIsSubmitting(false);
  }, [isSubmitting]);

  useEffect(() => {
    setInputs({
      email: "",
      message: "",
      nickname: "",
    });
    setIsSubmitted(false);
    setFetching(false);
  }, [isSubmitted]);

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(inputs));
    setIsSubmitting(true);
    setCarajilloResponse();
  };

  return {
    inputs,
    handleChange,
    handleSubmit,
    errors,
    carajilloResponse,
    fetching,
  };
};

export default UseForm;
