import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { HashLink } from "react-router-hash-link";
import logoWithName from "../../assets/logo_and_name.png";

const GetInTouch = (props) => {
  const ref = useRef(null);

  useEffect(() => {
    props.setHeight(ref.current.clientHeight);
  }, [props.screenWidth]);

  return (
    <div ref={ref} className="d-flex get-in-touch">
      <div className="first-container d-flex flex-column justify-content-end align-items-start">
        <img src={logoWithName} alt="Carajillo Logo" />
        <p className="fw-bold text-secondary">Shaking up everyday code</p>
        <button type="button" className="btn btn-primary rounded-pill">
          <HashLink
            smooth
            to="/#contact"
            className="text-light fw-bold text-decoration-none"
          >
            Let&apos;s get in touch
          </HashLink>
        </button>
      </div>
      <div className="second-container"></div>
    </div>
  );
};

GetInTouch.propTypes = {
  setHeight: PropTypes.func,
  screenWidth: PropTypes.number,
};

export default GetInTouch;