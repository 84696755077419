import React from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import logoFooter from "../../assets/logo_mobileHeader.png";
import hamburger from "../../assets/header/hamburger-button.png";
import close from "../../assets/header/close.png";
import Copyright from "../copyright";

const NavbarMobile = (props) => {
  let navigate = useNavigate();
  let contactSection = props.contact;
  let aboutSection = props.about;
  let servicesSection = props.services;
  // let blogHomeSection = props.blogHome

  const handleClick = (section) => {
    setTimeout(() => {
      navigate("/");
      section.scrollIntoView({ behavior: "smooth" });
    }, 400);
  };

  return (
    <nav className="carajillo-nav navbar navbar-light bg-light fixed-top">
      <div className="container-fluid bg-light p-0">
        <Link to="/" className="navbar-brand pointer">
          <img src={logo} alt="Carajillo logo" width="36" height="40" />
        </Link>
        <button
          className="btn btn-outline-light"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <img src={hamburger} alt="menu button" width="33.33" height="23.33" />
        </button>
        <div
          className="offcanvas offcanvas-end mobile-menu"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <img
              src={logo}
              alt="Carajillo logo"
              width="36"
              height="40"
              className="offcanvas-title"
              id="offcanvasNavbarLabel"
            />
            <img
              src={close}
              alt="close button"
              width="28.54"
              height="28.54"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              className="pointer"
            />
          </div>
          <div className="offcanvas-body d-flex flex-column">
            <ul className="navbar-nav h-100 w-100 justify-content-end flex-grow-1 pe-3">
              <li className="nav-item">
                <Link to="/" className="nav-link text-light fw-bold fs-4">
                  Home
                </Link>
              </li>
              <li
                className="nav-item"
                data-bs-dismiss="offcanvas"
                onClick={() => handleClick(aboutSection)}
              >
                <p className="nav-link text-light fw-bold fs-4 m-0 pointer">
                  About Us
                </p>
              </li>
              <li
                className="nav-item"
                data-bs-dismiss="offcanvas"
                onClick={() => handleClick(servicesSection)}
              >
                <p className="nav-link text-light fw-bold fs-4 m-0 pointer">
                  Services
                </p>
              </li>
              {/* <li className="nav-item" data-bs-dismiss="offcanvas"  onClick={() => handleClick(blogHomeSection)} >
                            <p className="nav-link text-light fw-bold fs-4 m-0 pointer">Blog</p>
                        </li> */}
              <li
                className="nav-item"
                data-bs-dismiss="offcanvas"
                onClick={() => handleClick(contactSection)}
              >
                <p className="nav-link text-light fw-bold fs-4 m-0 pointer">
                  Contact
                </p>
              </li>
              <footer className="h-100 w-100 d-flex align-items-end justify-content-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={logoFooter}
                      alt="carajillo logo"
                      width="168"
                      height="40"
                    />
                  </div>
                  <div className="nav-copyright">
                    <Copyright />
                  </div>
                </div>
              </footer>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

NavbarMobile.propTypes = {
  contact: PropTypes.instanceOf(Element),
  about: PropTypes.instanceOf(Element),
  services: PropTypes.instanceOf(Element),
};

export default NavbarMobile;