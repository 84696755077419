import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap";
import "../src/stylesheets/homepage.scss";
import Home from "../src/pages/home";

const Homepage = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}></Route>
      </Routes>
    </Router>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <Homepage />,
    document.body.appendChild(document.createElement("div"))
  );
});
