export default function postRequest(
  inputs,
  setCarajilloResponse,
  setIsSubmitted
) {
  const failureMessage =
    "We are unable to process your request at the moment. Please try later, or send us an e-mail to business@caraji.io";
  const successMessage =
    "We have received your message, and will respond promptly";
  const networkFailure = "Please check your network connection, and try later";
  const nextAction = function (messageToUser) {
    setCarajilloResponse(messageToUser);
    setIsSubmitted(true);
  };

  fetch(`/homepage`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(inputs),
  }).then(
    (res) => {
      if (res.ok) {
        nextAction(successMessage);
      } else {
        nextAction(failureMessage);
      }
    },
    (err) => {
      console.log(err);
      setCarajilloResponse(networkFailure);
    }
  );
}
