import React from "react";
import UseForm from "./useForm";
import validate from "./validateFormInfo";
import postRequest from "./postRequest";

const ContactForm = () => {
  const {
    handleChange,
    handleSubmit,
    inputs,
    errors,
    carajilloResponse,
    fetching,
  } = UseForm(validate, postRequest);

  return (
    <form onSubmit={handleSubmit}>
      {/* Input for mail_form gem to trick bots */}
      <div className="visually-hidden">
        <label htmlFor="nickname">Nickname</label>
        <input
          type="text"
          id="nickname"
          name="nickname"
          placeholder="nickname"
          value={inputs.nickname}
          onChange={handleChange}
        />
      </div>
      {/* Inputs for the user */}
      <div className="email-input">
        <label htmlFor="email" className="visually-hidden">
          email address
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={inputs.email}
          onChange={handleChange}
          className="form-control border border-primary rounded"
          placeholder="E-mail"
        />
        {errors.email && <p className="text-primary fw-bold">{errors.email}</p>}
      </div>
      <div className="message-input">
        <label htmlFor="message" className="visually-hidden">
          write your message here
        </label>
        <textarea
          type="text"
          id="message"
          name="message"
          value={inputs.message}
          onChange={handleChange}
          className="form-control border border-primary rounded text-secondary"
          placeholder="Your message"
        ></textarea>
        {errors.message && (
          <p className="text-primary fw-bold">{errors.message}</p>
        )}
      </div>
      <div className="send-message d-flex flex-column align-items-center">
        {fetching && (
          <div className="mb-4 w-100 d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {carajilloResponse && (
          <p className="mb-4 w-100 text-primary fw-bold text-center">
            {carajilloResponse}
          </p>
        )}
        <button
          type="submit"
          className="btn btn-primary text-light text-center fw-bold rounded-pill"
        >
          Send message
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
