import React, { useState, useEffect } from "react";

const Copyright = () => {
  const [currentYear, setCurrentYear] = useState(0);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setCurrentYear(year);
  });

  return (
    <p className="text-info small m-0">
      Copyright © {currentYear} Carajillo -All rights reserved
    </p>
  );
};

export default Copyright;