export default function validate(values) {
  let errors = {};

  if (!values.message.trim()) {
    errors.message = "Message required";
  } else if (values.message.length > 1500) {
    errors.message = "Message should be less than 1500 characters";
  }

  if (!values.email) {
    errors.email = "E-mail required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "E-mail is invalid";
  }

  return errors;
}
