import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ContactForm from "./contactForm/contactForm";

const Contact = (props) => {
  const ref = useRef(null);

  useEffect(() => {
    props.setContact(ref.current);
  });

  return (
    <section ref={ref} className="contact" id="contact">
      <div className="contact-title d-flex justify-content-center align-items-end">
        <h1 className="text-info text-center">We’d love to hear from you.</h1>
      </div>
      <div className="contact-description">
        <p className="text-secondary text-center">
          We know it’s hard to run a business,
        </p>
        <p className="text-secondary text-center">
          that is why we adapt solutions based on your budget.
        </p>
      </div>
      <ContactForm />
    </section>
  );
};

Contact.propTypes = {
  setContact: PropTypes.func,
};

export default Contact;