import React from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import twitter from "../assets/footer/twitterLogo.png";
import facebook from "../assets/footer/facebookLogo.png";
import github from "../assets/footer/githubLogo.png";
import logoFooter from "../assets/logo_footer.png";
import Copyright from "./copyright";

const Footer = () => {
  return (
    <section className="footer bg-secondary">
      <div className="logo-networks">
        <Link to="/">
          <img src={logoFooter} alt="carajillo logo" width="168" height="40" />
        </Link>
        <div className="networks">
          <div className="d-flex justify-content-between ps-3">
            <img
              src={twitter}
              alt="carajillo twitter link"
              width="24"
              height="19.5"
            />
            <img
              src={facebook}
              alt="carajillo facebook link"
              width="24"
              height="23.85"
            />
            <img
              src={github}
              alt="carajillo github link"
              width="24"
              height="23.41"
            />
          </div>
          <p className="large text-light text-end fw-bold m-0">
            contact@caraji.io
          </p>
        </div>
      </div>

      <div className="nav-copyright">
        <nav>
          <ul className="nav flex-column justify-content-between align-items-start">
            <li className="nav-item">
              <HashLink
                smooth
                to="/#aboutUs"
                className="nav-link fw-bold text-light p-0"
              >
                About Us
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                smooth
                to="/#services"
                className="nav-link fw-bold text-light p-0"
              >
                Services
              </HashLink>
            </li>
            {/* <li className="nav-item">
                            <HashLink smooth to="/#blogHome" className="nav-link fw-bold text-light p-0">Blog</HashLink>
                        </li> */}
            <li className="nav-item">
              <HashLink
                smooth
                to="/#contact"
                className="nav-link fw-bold text-light p-0"
              >
                Contact
              </HashLink>
            </li>
          </ul>
        </nav>
        <div className="footer-copyright">
          <Copyright />
        </div>
      </div>
    </section>
  );
};

export default Footer;