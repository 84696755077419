import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import logoWithName from "../../assets/logo_and_name.png";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const NavbarDesktop = (props) => {
  const [selfHeight, setSelfHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setSelfHeight(ref.current.clientHeight);
  }, [props.screenWidth]);

  const style = () => {
    const offset = (props.headerHeight * 21) / 100;
    const marginBottom = offset - selfHeight;
    const styles = {
      marginTop: `-${offset}px`,
      marginBottom: `${marginBottom}px`,
    };
    return styles;
  };

  return (
    <nav
      style={style()}
      ref={ref}
      className="carajillo-nav-desktop navbar navbar-light bg-light"
    >
      <div className="container-fluid px-0">
        <Link to="/" className="navbar-brand">
          <img src={logoWithName} alt="Carajillo logo" />
        </Link>
        <ul className="nav justify-content-end">
          <li className="nav-item">
            <HashLink
              smooth
              to="/#aboutUs"
              className="nav-link fw-bold text-primary"
            >
              About Us
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink
              smooth
              to="/#services"
              className="nav-link fw-bold text-primary"
            >
              Services
            </HashLink>
          </li>
          {/* <li className="nav-item">
                        <HashLink smooth to="/#blogHome" className="nav-link fw-bold text-primary">Blog</HashLink>
                    </li> */}
          <li className="nav-item">
            <HashLink
              smooth
              to="/#contact"
              className="nav-link fw-bold text-primary"
            >
              Contact
            </HashLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

NavbarDesktop.propTypes = {
  screenWidth: PropTypes.number,
  headerHeight: PropTypes.number,
};

export default NavbarDesktop;