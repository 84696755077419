import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import aboutImage from "../assets/about/aboutImage.png";
import backdrop from "../assets/about/servicesImageBackdrop.png";
import servicesImage1 from "../assets/about/servicesImage1.png";
import servicesImage2 from "../assets/about/servicesImage2.png";
import servicesImage3 from "../assets/about/servicesImage3.png";
import react from "../assets/about/logo_react.png";
import go from "../assets/about/logo_go.png";
import js from "../assets/about/logo_js.png";
import node from "../assets/about/logo_nodejs.png";
import rails from "../assets/about/logo_rails.png";
import ruby from "../assets/about/logo_ruby.png";

const About = (props) => {
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);

  useEffect(() => {
    props.setAbout(aboutRef.current);
    props.setServices(servicesRef.current);
  });

  return (
    <div
      className="container-fluid about-background"
      id="aboutUs"
      ref={aboutRef}
    >
      <section className="about">
        <div className="first-container">
          <h1 className="text-info text-center m-0">
            The perfect mix between experience and determination
          </h1>
          <p className="text-light text-center m-0">
            We work with the energy and seriousness of an espresso coffee and
            the delight of a good spirit.
          </p>
        </div>
        <div className="d-flex justify-content-center second-container">
          <img src={aboutImage} alt="people working around a computer" />
        </div>
      </section>

      <section className="services" ref={servicesRef} id="services">
        <div className="service">
          <div className="illustration">
            <div className="image-backdrop d-flex align-items-center justify-content-center">
              <img src={backdrop} alt="backdrop" width="120" height="120" />
            </div>
            <div className="services-image d-flex align-items-center justify-content-center">
              <img
                src={servicesImage1}
                alt="software development"
                width="96"
                height="80"
              />
            </div>
          </div>
          <div className="services-title">
            <h4 className="text-info text-center m-0">Software Development</h4>
          </div>
          <p className="text-light text-center m-0">
            We are driven by our clients and latest technologies. Our aim is to
            develop practical and conscious software for you to use.
          </p>
        </div>

        <div className="service">
          <div className="illustration">
            <div className="image-backdrop d-flex align-items-center justify-content-center">
              <img src={backdrop} alt="backdrop" width="120" height="120" />
            </div>
            <div className="services-image d-flex align-items-center justify-content-center">
              <img
                src={servicesImage2}
                alt="software development"
                width="80"
                height="80"
              />
            </div>
          </div>
          <div className="services-title">
            <h4 className="text-info text-center m-0">Quality Assurance</h4>
          </div>
          <p className="text-light text-center m-0">
            The delicacy of each ingredient in a timely manner. Everything goes
            through its due quality process.
          </p>
        </div>

        <div className="service">
          <div className="illustration">
            <div className="image-backdrop d-flex align-items-center justify-content-center">
              <img src={backdrop} alt="backdrop" width="120" height="120" />
            </div>
            <div className="services-image d-flex align-items-center justify-content-center">
              <img
                src={servicesImage3}
                alt="software development"
                width="84"
                height="80"
              />
            </div>
          </div>
          <div className="services-title">
            <h4 className="text-info text-center m-0">UX/UI</h4>
          </div>
          <p className="text-light text-center m-0">
            Our products are easy to use. They leave the essence of what the
            user needs.
          </p>
        </div>
      </section>

      <div className="software-logos">
        <div className="d-flex justify-content-between px-3 first-container">
          <img src={ruby} width="40" height="40" />
          <img src={rails} width="114" height="40" />
          <img src={js} width="35" height="40" />
          <img src={react} width="50" height="40" className="extra-logo" />
          <img src={node} width="142" height="40" className="extra-logo" />
        </div>
        <div className=" px-3 second-container">
          <img src={react} width="50" height="40" />
          <img src={node} width="142" height="40" />
        </div>
      </div>
    </div>
  );
};

About.propTypes = {
  setAbout: PropTypes.func,
  setServices: PropTypes.func,
};

export default About;